import {Button, Form, Input, InputNumber, message, Modal} from "antd";
import React, { useState } from 'react';
import axios from "axios";
import {baseURL} from "../service/ApiService";
import TextArea from "antd/es/input/TextArea";
const GeneralConfirmModal = ({buttonName, title, callback}) => {
    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true)
    }
    return (
        <>
            <a onClick={showModal}>{buttonName}</a>
            <Modal
                title={"Confirm"}
                centered
                open={open}
                onOk={() => {
                    callback()
                    setOpen(false)
                }}
                okText={"Yes"}
                onCancel={() => setOpen(false)}
            >
                {title}
            </Modal>
        </>
    )
}

export default GeneralConfirmModal;