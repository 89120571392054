import {Button, Form, Input, InputNumber, Modal} from "antd";
import React, { useState } from 'react';
import axios from "axios";
import {baseURL} from "../service/ApiService";
const UserEditFormModal = ({buttonName, user, callback}) => {
    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true)
    }
    const onFinish = (values) => {
        values["id"] = user.id;
        console.log('Form values:', values);
        axios.put(`${baseURL}/api/web/onboarding-info`, values).then(
            (res) => {
                setOpen(false); // Close modal on successful submit
                callback();
            }
        );
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Form failed:', errorInfo);
    };
    const [form] = Form.useForm();
    return (
        <>
            <a onClick={showModal}>{buttonName}</a>
            <Modal
                title='Update User Details'
                centered
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <Form
                    form={form}
                    initialValues={user}
                    layout={"vertical"}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Username"
                        name="name"
                    >

                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Total Daily Token"
                        name="totalDailyToken"
                        rules={[
                            {
                                required: true,
                                message: 'Please input number of token!',
                            },
                        ]}
                    >
                        <InputNumber min={0} max={9999} style={{width: '100%'}} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
                        <Button type="default" onClick={() => setOpen(false)} style={{ marginRight: '8px' }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default UserEditFormModal;