import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {Table, Tag} from 'antd';
import axios from 'axios';
import {baseURL} from '../service/ApiService';

import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useState } from 'react';
const UserFeedBack = () => {
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const updateTable = async () => {
        setLoading(true)
        axios.get(`${baseURL}/api/user/feedbacks?page=${tableParams.pagination.current - 1}&size=${tableParams.pagination.pageSize}`).then(
            (res) => {
                setLoading(false)
                setDataSource(res.data.content.map(item => (
                    {
                        id: item.id,
                        username: item.username,
                        userId: item.userId,
                        type: item.type,
                        content: item.content,
                        timestamp: new Date(item.timestamp).toLocaleString()
                    })
                ));
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: res.data.totalElements,
                        showTotal: (total) => `Total ${total} feedback`
                    },
                });
            }
        );
    }
    useEffect(() => {
        updateTable();
    }, [JSON.stringify(tableParams)])
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
        },
        {
            title: 'Name',
            dataIndex: 'username',
            width: 100,
            render: (row) => <span style={{fontWeight: "bolder"}}>{row.length === 0? "Name Not provided": row}</span>
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 100,
            render: (row) => <Tag color={row==="bug"?"magenta":"blue"}>{row}</Tag>
        },
        {
            title: 'Content',
            dataIndex: 'content',
            render: (row) => <p style={{whiteSpace: "pre-line"}}>{row}</p>
        },
        {
            title: 'Time',
            dataIndex: 'timestamp',
            width: 200,
        },
    ];
    const Row = ({ children, ...props }) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key'],
        });
        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                },
            ),
            transition,
            ...(isDragging
                ? {
                    position: 'relative',
                    zIndex: 9999,
                }
                : {}),
        };
        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React.Children.map(children, (child) => {
                    if (child.key === 'sort') {
                        return React.cloneElement(child, {
                            children: (
                                <MenuOutlined
                                    ref={setActivatorNodeRef}
                                    style={{
                                        touchAction: 'none',
                                        cursor: 'move',
                                    }}
                                    {...listeners}
                                />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };
    const [dataSource, setDataSource] = useState([]);

    return (
        <>
            <DndContext modifiers={[restrictToVerticalAxis]} >
                <SortableContext
                    items={dataSource.map((i) => i.key)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        components={{
                            body: {
                                row: Row,
                            },
                        }}
                        rowKey="key"
                        columns={columns}
                        dataSource={dataSource}
                        pagination={tableParams.pagination}
                        loading={loading}
                        onChange={handleTableChange}
                    />
                </SortableContext>
            </DndContext>
        </>
    );
};
export default UserFeedBack;