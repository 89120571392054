import {Button, Form, message, Modal} from "antd";
import React, { useState } from 'react';
import axios from "axios";
import {baseURL} from "../service/ApiService";
import TextArea from "antd/es/input/TextArea";
const SendNotificationModal = ({buttonName, user}) => {
    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true)
    }
    const onFinish = (values) => {
        console.log('Form values:', values);
        axios.post(`${baseURL}/api/web/send-notification?alert=${values.message}&userId=${user.id}`).then(
            (res) => {
                setOpen(false); // Close modal on successful submit
            }
        ).catch((reason) => {
            message.error(reason.response.data.detail)
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Form failed:', errorInfo);
    };
    const [form] = Form.useForm();
    return (
        <>
            <a onClick={showModal}>{buttonName}</a>
            <Modal
                title={"Send Notification to user " + user.name}
                centered
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <Form
                    form={form}
                    initialValues={user}
                    layout={"vertical"}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Message"
                        name="message"
                        rules={[
                            {
                                required: true,
                                message: "You can't send empty message!",
                            },
                        ]}
                    >
                        <TextArea placeholder={"Input message you want to send"} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
                        <Button type="default" onClick={() => setOpen(false)} style={{ marginRight: '8px' }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default SendNotificationModal;