import React, {useEffect, useState} from "react";
import {Button, Card, message, Modal, Radio, Space} from "antd";
import axios from "axios";
import {baseURL} from "../service/ApiService";

const GptModelEditModal = ({buttonName, title, model, callback}) => {
    const [open, setOpen] = useState(false);
    const [modelList, setModelList] = useState([]);
    const [currentModel, setCurrentModel] = useState(model);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        console.log(model);
        setCurrentModel(model)
    }, [model]);
    const getModelList = async () => {
        setLoading(true)
        axios.get(`${baseURL}/api/web/gpt-models`).then(
            (res) => {
                console.log(res.data);
                setModelList(res.data);
                setLoading(false)
            }
        );
        setOpen(true)
    }
    const saveCurrentModel = async () => {
        axios.post(`${baseURL}/api/web/current-model?modelId=${currentModel}`).then(() => callback())
    }

    const showModal = () => {
        getModelList()
    }
    return (
        <>
            <Button type={"primary"} onClick={showModal}>{buttonName}</Button>
            <Modal
                title={title}
                centered
                open={open}
                onOk={() => {
                    saveCurrentModel()
                    setOpen(false)
                }}
                okText={"Save"}
                onCancel={() => setOpen(false)}
            >
                <Card loading={loading}>
                    <Radio.Group value={currentModel} onChange={(e) => setCurrentModel(e.target.value)}>
                        <Space direction="vertical">
                            {
                                modelList.map(item => {
                                    return <Radio key={item.id} value={item.id}>{item.modelName}</Radio>
                                })
                            }
                        </Space>
                    </Radio.Group>
                </Card>
            </Modal>
        </>
    )
}
export default GptModelEditModal