import {Button, Form, Input, InputNumber, message, Modal, Row} from "antd";
import { formatDistanceToNow } from 'date-fns';
import React, { useState } from 'react';
import axios from "axios";
import {baseURL} from "../service/ApiService";

const UserViewStudyProgressModal = ({buttonName, user, json, callback}) => {
    const [open, setOpen] = useState(false);
    const [lastUpdateTime, setLastUpdateTime] = useState(null);
    const [loading, setLoading] = useState(false);
    const showModal = () => {
        setLoading(true)
        // webControllerApi.getUserTodayProgress1(user.id, (err, data, response) => {
        //     setLastUpdateTime(data);
        //     setLoading(false)
        //     setOpen(true)
        // })
        axios.get(`${baseURL}/api/web/daily-progress?userId=${user.id}`).then(
            (res) => {
                console.log(res.data.content);
                setLastUpdateTime(res.data);
                setLoading(false)
            }
        );
        setOpen(true)
    }
    const onClickClear = () => {
        // webControllerApi.clearStudyProgress(user.id, () => {
        //     message.info("Successfully clear today's study progress!").then(() => setOpen(false));
        // })
        axios.delete(`${baseURL}/api/web/clear-study-progress?userId=${user.id}`).then(
            (res) => {
                message.info("Successfully clear today's study progress!");
            }
        );
        setOpen(false)
    };

    return (
        <>
            <a onClick={showModal}>{buttonName}</a>
            <Modal
                title='Study Progress'
                centered
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <div style={{padding: '20px', textAlign: 'center'}}>
                    <div style={{fontSize: '16px', marginBottom: '20px'}}>
                        <strong>Last update
                            time:</strong> {loading ? 'Loading...' : (lastUpdateTime ? (formatDistanceToNow(new Date(lastUpdateTime)) + " ago") : 'No study progress today')}
                    </div>
                    <Button
                        type="primary"
                        danger
                        onClick={() => onClickClear()}
                        style={{width: '100%'}}
                    >
                        Clear Progress
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default UserViewStudyProgressModal;