import {LoadingOutlined, MenuOutlined, SyncOutlined} from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {Table, Modal, Spin, Col, Tag} from 'antd';
import { Input } from 'antd';
import { Radio } from 'antd';
import axios from 'axios';
import { message } from 'antd';
import {baseURL} from '../service/ApiService';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useState } from 'react';
import GptModelEditModal from "../modal/GptModelEditModal";
const { TextArea } = Input;
const GptPromptTable = () => {
    const updateTable = async () => {
        axios.get(`${baseURL}/api/prompt?page=0&size=10`).then(
            (res) => {
                console.log(res.data.content);
                setDataSource(res.data.content.map(item => ({ key: item.id, id: item.id, description: item.description, promptChinese: item.promptChinese, promptEnglish: item.promptEnglish })));
                setLoading(false);
            }
        );
    }
    useEffect(() => {
        updateTable();
        getCurrentModel();
    }, [])
    const [languageMode, setLanguageMode] = useState("Chinese");
    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'This is a success message',
        });
    };
    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'This is an error message',
        });
    };
    const columns = [
        {
            key: 'sort',
        },
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
        },
        {
            title: 'Name',
            dataIndex: 'description',
            width: 100,
        },
        {
            title: 'Propmt text',
            dataIndex: languageMode === "Chinese" ? 'promptChinese' : 'promptEnglish',
            editable: true,
            render: (text) => {
                const regex = /\${([^}]+)}/g;
                const boldText = text.replace(regex, '<strong>$&</strong>');
                return <div dangerouslySetInnerHTML={{ __html: boldText }} />;
            },
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (row) => <a onClick={() => { setOpen(true); setCurrent((prev) => ({ ...prev, id: row.id, prompt: languageMode === "Chinese" ? row.promptChinese : row.promptEnglish, description: row.description })); }}  >Edit</a>
        },
    ];
    const Row = ({ children, ...props }) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key'],
        });
        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                },
            ),
            transition,
            ...(isDragging
                ? {
                    position: 'relative',
                    zIndex: 9999,
                }
                : {}),
        };
        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React.Children.map(children, (child) => {
                    if (child.key === 'sort') {
                        return React.cloneElement(child, {
                            children: (
                                <MenuOutlined
                                    ref={setActivatorNodeRef}
                                    style={{
                                        touchAction: 'none',
                                        cursor: 'move',
                                    }}
                                    {...listeners}
                                />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };
    const [dataSource, setDataSource] = useState([]);
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(null);
    const [loading, setLoading] = useState(true);
    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setDataSource((previous) => {
                const activeIndex = previous.findIndex((i) => i.key === active.id);
                const overIndex = previous.findIndex((i) => i.key === over?.id);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }
    };
    const save = (id, text) => {
        const data = {
            id: id,
            text: text,
        }
        console.log(languageMode);
        axios.put(`${baseURL}/api/prompt/${languageMode}`, data).then(
            () => {
                updateTable();
                setOpen(false);
            }
        )
    }
    const [gptModel, setGptModel] = useState(null)
    const getCurrentModel = async () => {
        axios.get(`${baseURL}/api/web/current-model`).then(
            (res) => {
                console.log(res.data);
                setGptModel(res.data);
                setLoading(false)
            }
        );
    }

    return (
        <>
                <Radio.Group onChange={(e) => { setLanguageMode(e.target.value) }} value={languageMode} style={{ marginBottom: '10px' }}>
                    <Radio value={"Chinese"}>Chinese</Radio>
                    <Radio value={"English"}>English</Radio>
                </Radio.Group>
                <GptModelEditModal model={gptModel?.id} buttonName={"Choose GPT Model"} title={"Choose GPT Model"} callback={() => getCurrentModel()}/>
            {/*<h4>Powered by {gptModel?.modelName}</h4>*/}
            <Row>
                <Tag icon={<SyncOutlined spin />} color="processing">
                    Powered by {gptModel?.modelName}
                </Tag>
            </Row>

            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                        <SortableContext
                            items={dataSource.map((i) => i.key)}
                            strategy={verticalListSortingStrategy}
                        >
                            <Table
                                components={{
                                    body: {
                                        row: Row,
                                    },
                                }}
                                rowKey="key"
                                columns={columns}
                                dataSource={dataSource}
                                loading={loading}
                            />
                        </SortableContext>
                    </DndContext>
            <Modal
                title={current?.description}
                centered
                open={open}
                onOk={() => save(current?.id, current?.prompt)}
                onCancel={() => setOpen(false)}
                okText="Save"
                width={1000}
            >
                <TextArea rows={8} value={current?.prompt} onChange={(e) => setCurrent((prev) => ({ ...prev, prompt: e.target.value }))} />
            </Modal>
        </>
    );
};
export default GptPromptTable;