import {Button, Form, Input, Modal} from "antd";
import React, { useEffect, useState } from 'react';
import axios from "axios";
import {baseURL} from "../service/ApiService";
const BookEditFormModal = ({buttonName, book, updateBookList}) => {
    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true)
    }
    const onFinish = (values) => {
        values["id"] = book.id;
        console.log('Form values:', values);
        axios.post(`${baseURL}/api/book`, values).then(
            (res) => {
                setOpen(false); // Close modal on successful submit
                updateBookList();
            }
        );
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Form failed:', errorInfo);
    };
    const [form] = Form.useForm();
    return (
        <>
            <a onClick={showModal}>{buttonName}</a>
            <Modal
                title='Update Book Details'
                centered
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <Form
                    form={form}
                    initialValues={book}
                    layout={"vertical"}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Book Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please input title of the book!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please input description of the book!',
                            },
                        ]}
                    >
                        <Input.TextArea autoSize={{
                            minRows: 6,
                            maxRows: 6,
                        }} value={book.description}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
                        <Button type="default" onClick={() => setOpen(false)} style={{ marginRight: '8px' }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default BookEditFormModal;