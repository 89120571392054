import React, { useEffect, useState } from 'react';
import {AppstoreOutlined, MailOutlined, ReadOutlined, UserOutlined} from '@ant-design/icons';
import { Menu } from 'antd';
import { Outlet } from 'react-router-dom';
import Books from "./gptPrompt/Books";
const items = [
  {
    label: 'GPT Prompt',
    key: '/prompt',
    icon: <AppstoreOutlined />,
    href: '/prompt'
  },
  {
    label: 'Books',
    key: '/books',
    icon: <ReadOutlined />,
    href: '/books'
  },
  {
    label: 'User Feedback',
    key: '/feedback',
    icon: <MailOutlined />,
    href: '/feedback'
  },
  {
    label: 'Users',
    key: '/users',
    icon: <UserOutlined />,
    href: '/users'
  },
];
const Root = () => {
  const [current, setCurrent] = useState('mail');
  useEffect(() => {
    setCurrent(window.location.pathname);
  }, [])
  
  const onClick = (e) => {
    window.location.href = e.key;
  };
  return (
  <>
  <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
  <div style={{margin: '10px'}}>
  <Outlet/>
  </div>
  </>
  );
};
export default Root;